export const ERROR_MESSAGES = {
    setup: "Contact Form is not properly configured. If you are using custom configurations, " +
      "please ensure the query param is correctly encoded.",
    submit: "Error while sending your enquiry"
};
export const apiUrl = process.env.REACT_APP_FUZEY_API_URL || "http://localhost:5004";

// Taken from Chakra UI https://github.com/chakra-ui/chakra-ui/blob/790d2417a3f5d59e2d69229a027af671c2dc0cbc/packages/theme/src/foundations/colors.ts
export type ColorScheme =
  | "whiteAlpha"
  | "blackAlpha"
  | "gray"
  | "red"
  | "orange"
  | "yellow"
  | "green"
  | "teal"
  | "blue"
  | "cyan"
  | "purple"
  | "pink"
  | "linkedin"
  | "facebook"
  | "messenger"
  | "whatsapp"
  | "twitter"
  | "telegram";
