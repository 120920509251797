import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DeepRequired, FieldError, FieldErrorsImpl, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";

import Alert from "../alert";
import Success from "../success";
import getContactFormSchema from "../../validations/schema";
import ContactFormService, { ContactFormDto } from "../../services/form";
import { useFormSettings } from '../../contexts/settings';
import { ERROR_MESSAGES } from "../../constants";
import Submit from "../submit";
import Footer from "../footer";
import Input, { Control } from "../input";
import PhoneInput from "../phone-input";
import Header from "../header";
import Spinner from "../spinner";
import Uploader from "../uploader";

export type Inputs = {
  first_name: string;
  last_name: string;
  phone_number: string;
  message: string;
  email: string;
  files?: File[];
};

const Form = styled.form.attrs((props: {config: any}) => props)`
    padding-left: ${(props: any) => props.config?.compactVersion ? props.theme.padding.x.l : props.theme.padding.x.xl};
    padding-right: ${(props: any) => props.config?.compactVersion ? props.theme.padding.x.l : props.theme.padding.x.xl};
    margin-left: auto;
    margin-right: auto;
    max-width: ${(props: any) => props.theme.maxWidth};
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    color: ${(props: any) => props.config?.textColor || props.theme.colors.text};
    background-color: ${(props: any) => props.config?.bgColor || props.theme.colors.grayBackground};
    border-radius: ${(props: any) => props.theme.border.radius.mild};
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: stretch;

  @media (max-width: ${(props: any) => props.theme.mobile.maxWidth}) {
    flex-direction: column;
  }
`;

function ContactForm({
  isSubmitted = false
}: {
  isSubmitted?: boolean;
}) {
  if (isSubmitted) {
    return (<Success />);
  }

  const recaptchaRef = React.useRef<ReCAPTCHA>({} as ReCAPTCHA);

  const {
    isLoaded,
    loadSettings,
    formSettings,
    inputSettings,
    errorOnLoading
  } = useFormSettings();

  // ideally we would rely on the isSubmitting coming from react-hook-form, however
  // google captcha does not provide a callback when the captcha challenge modal
  // is closed without a formal result, causing the form to stuck on isLoading state.
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [unexpectedError, setUnexpectedError] = useState<string>();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  useEffect(() => {
    if (!isLoaded && !errorOnLoading) {
      loadSettings(queryParams);
    } else if (errorOnLoading) {
      setUnexpectedError(ERROR_MESSAGES.setup);
    }
  }, [isLoaded, errorOnLoading]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState,
  } = useForm<Inputs>({
    resolver: yupResolver(getContactFormSchema(formSettings?.onlySMS)),
  });

  const formRef = useRef<any>(null);

  const fadeoutFormAndRedirectToSuccess = () => {
    if (formRef.current) {
      formRef.current.style.transition = "opacity 1s";
      formRef.current.style.opacity = "0";
    }
    setTimeout(() => {
      navigate(`/success?config=${JSON.stringify(formSettings)}`, {
        replace: true
      });
    }, 700);
  };

  const watchFiles = watch("files");

  const submitEnquiry: SubmitHandler<Inputs> = async ({
    first_name: name,
    last_name: surname,
    phone_number,
    email,
    message: body,
    files
  }) => {
    const token = await ContactFormService.getCaptchaToken(recaptchaRef);

    const payload: ContactFormDto = {
      merchant_id: formSettings!.merchantId,
      name,
      surname,
      phone_number: phone_number.replaceAll(" ", ""),
      body,
      files
    };

    if (!formSettings?.onlySMS) {
      payload.email = email;
    }

    setIsSubmitting(true);

    const success =
      token &&
      (await ContactFormService.submitEnquiry(token, payload));

    if (success) {
      fadeoutFormAndRedirectToSuccess();
    } else {
      setUnexpectedError(ERROR_MESSAGES.submit);
      setIsSubmitting(false);
    }
  };

  const getEmailError = (errors: FieldErrorsImpl<DeepRequired<Inputs>>) => {
    let emailError;

    if (errors.email && errors.email.message) {
      emailError = {
        ...errors.email,
        message:
          errors.email.message[0].toUpperCase() + errors.email.message.slice(1),
      };
    }

    return emailError;
  }

  const { errors } = formState;

  return (
    !isLoaded && !errorOnLoading ? <Spinner /> : (
    <Form
      config={formSettings}
      onSubmit={handleSubmit(submitEnquiry)}
      ref={formRef}
    >
      <Header config={formSettings} />
      {unexpectedError && <Alert text={unexpectedError} />}
      <Group>
        <Input
          id="first_name"
          placeholder="Jane"
          label="First name"
          settings={inputSettings}
          {...register("first_name")}
          error={errors?.first_name}
        />
        <Input
          id="last_name"
          placeholder="Doe"
          label="Last name"
          settings={inputSettings}
          {...register("last_name")}
          error={errors?.last_name}
        />
      </Group>
      <Control compact={formSettings?.compactVersion}>
        <PhoneInput
          id="phone_number"
          label="Phone Number"
          defaultCountry={formSettings?.defaultCountry}
          settings={inputSettings}
          {...register("phone_number")}
          error={errors?.phone_number}
        />
      </Control>
      {formSettings?.onlySMS ? null : (
        <Input
          id="email"
          placeholder="jane@doe.com"
          label="Email"
          settings={inputSettings}
          {...register("email")}
          error={getEmailError(errors)}
        />
      )}
      <Input
        id="message"
        as="textarea"
        placeholder="Please, type your message here..."
        label="Message"
        settings={inputSettings}
        {...register("message")}
        error={errors?.message}
      />
      {formSettings?.hasFileUpload && !formSettings?.onlySMS ? (
        <Uploader
          id="file"
          label="Attach a file"
          currentValue={watchFiles}
          settings={inputSettings}
          {...register("files")}
          error={errors?.files as FieldError}
        />
      ) : null}
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={
          process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_SITE_KEY || "undefined"
        }
        size="invisible"
      />
      <Submit config={formSettings} isDisabled={isSubmitting} />
      <Footer config={formSettings} />
    </Form>)
  );
}

export default ContactForm;
