import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: ${(props: any) => props.theme.colors.alert};
  padding-left: ${(props: any) => props.theme.padding.x.l};
  padding-right: ${(props: any) => props.theme.padding.x.l};
  padding-top: ${(props: any) => props.theme.padding.y.m};
  padding-bottom: ${(props: any) => props.theme.padding.y.m};
  margin-top: ${(props: any) => props.theme.margin.y.m};
  border-radius: ${(props: any) => props.theme.border.radius.mild};
  font-weight: ${(props: any) => props.theme.text.bold};
  line-height: ${(props: any) => props.theme.text.lineHeight.lg};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const Icon = styled.div`
  border-radius: ${(props: any) => props.theme.border.radius.full};
  width: ${(props: any) => props.theme.icon.size};
  height: ${(props: any) => props.theme.icon.size};
  background-color: ${(props: any) => props.theme.colors.red};
  margin-right: ${(props: any) => props.theme.margin.x.m};
  color: ${(props: any) => props.theme.colors.white};
  display: block;
  flex-shrink: 0;
  text-align: center;
`;

function Alert({ text }: { text: string }) {
  return (
    <Wrapper>
      <Icon>&#33;</Icon>
      <span>{text}</span>
    </Wrapper>
  );
}

export default Alert;
