import React, { ChangeEvent, forwardRef, ForwardRefRenderFunction, ReactNode, RefObject, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FieldError, useForm } from "react-hook-form";
import { InputProps, InputError, Label } from "../input";
import { ContactFormConfigProps, ContactFormInputConfigProps } from "../../contexts/config";

type UploaderProps = {
  currentValue?: File[];
  label: string;
  id: string;
  name: string;
  error?: FieldError;
  formSettings?: ContactFormConfigProps | undefined;
  settings?: ContactFormInputConfigProps | undefined;
};

const Wrapper = styled.div<{settings: any}>`
  margin-left: ${(props: any) => props.theme.margin.x.xs};
  margin-right: ${(props: any) => props.theme.margin.x.xs};
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 1.5rem;
  border: 1px dashed gray;
  border-radius: 1rem;
  transition: 0.2s;

  &.is-active {
    background-color: rgba(0, 0, 0, 0.6);

    .fake-btn {
      background-color: black;
    }
  }

  .fake-btn {
    flex-shrink: 0;
    background-color: darkgray;
    color: white;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    margin-right: 0.75rem;
    text-transform: uppercase;
  }
  
  .file-msg {
    font-size: small;
    font-weight: 300;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;

    &:focus {
      outline: none;
    }
  }
`

const Uploader: ForwardRefRenderFunction<HTMLInputElement, UploaderProps> = ({
  currentValue,
  error,
  formSettings = undefined,
  settings = undefined,
  ...rest
}: UploaderProps, ref) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const defaultDropAreaText = (
    <>
      <span className="fake-btn">Choose files</span>
      <span className="file-msg">we accept PNG, JPG and PDF</span>
    </>
  );

  const [dropAreaText, setDropAreaText] = useState<ReactNode>(defaultDropAreaText);

  useEffect(() => {
    if (isActive) {
      if (currentValue && currentValue.length > 1) {
        setDropAreaText(
          <ul>
            {Object.entries(currentValue)?.map(([_idx, file], index) => {
              return (
                <li key={`file-name-${index + 1}`} className="file-msg">{file.name}</li>
              )
            })}
          </ul>
        );
      } else if (currentValue && currentValue.length === 1) {
        setDropAreaText(
          <span className="file-msg">{currentValue[0].name}</span>
        );
      } else {
        // useEffect is not fired when
        // choose file is canceled
        // There're other ways to handle this but doesn't
        // make sense do it now
        // We still need this "else" clause because of
        // files list is cleared when user clicks on cancel

        // setDropAreaText(
        //   <span style={{
        //     marginLeft: "auto",
        //     marginRight: "auto"
        //   }}>Waiting...</span>
        // );
        setDropAreaText(defaultDropAreaText);
      }
    } else {
      setDropAreaText(defaultDropAreaText);
    }
  }, [isActive, currentValue])

  return (
    <>
    {settings?.usePlaceholders === false && (
      <Label htmlFor={rest.id}>
        {rest.label}
      </Label>
    )}
    <Wrapper settings={settings}>
      {dropAreaText}
      <input
        onDragEnter={() => {
          setIsActive(true);
        }}
        onFocus={() => {
          setIsActive(true);
        }}
        onClick={() => {
          setIsActive(true);
        }}
        onDragLeave={() => {
          setIsActive(false);
        }}
        onBlur={() => {
          setIsActive(false);
        }}
        onDrop={() => {
          setIsActive(false);
        }}
        type="file"
        ref={ref}
        accept="image/png, image/jpeg, image/jpg, application/pdf"
        className={isActive ? "is-active" : ""}
        multiple={true}
        {...rest}
      />
    </Wrapper>
    {error && <InputError>{error?.message}</InputError>}
    </>
  );
}

export default forwardRef(Uploader);
