/* eslint-disable react/no-unused-prop-types */
import React from "react";
import styled from "styled-components";
import {ContactFormConfigProps} from "../../contexts/config";

interface ButtonProps {
    config?: ContactFormConfigProps;
    isDisabled: boolean;
}

const Button = styled.button.attrs((props: {config: any}) => props)`
    margin-top: ${(props: any) => props.theme.margin.y.m};
    margin-left: ${(props: any) => props.theme.margin.x.xs};
    margin-right: ${(props: any) => props.theme.margin.x.xs};
    border-radius: ${(props: any) => props.theme.border.radius.full};
    height: ${(props: any) => props.theme.element.height};
    font-weight: ${(props: any) => props.theme.text.semiBold};
    border: none;
    color: ${(props: any) => props.theme.isLightColorScheme(props.config?.buttonColorScheme) ? props.theme.colors.gray : props.theme.colors.white};
    cursor: pointer;

    &:disabled {
      opacity: ${(props: any) => props.theme.element.disabledOpacity};
      cursor: not-allowed;
      box-shadow: none;
    }

    background-color: ${(props: any) => props.theme.colorsSchemes[props.config?.buttonColorScheme || "blue"][500]};

    &:hover {
      background-color: ${(props: any) => props.theme.colorsSchemes[props.config?.buttonColorScheme || "blue"][700]};
    }
`;

function Submit({
  isDisabled = false,
  config
}: ButtonProps) {
    return (
      <Button
        type="submit"
        config={config}
        disabled={isDisabled}
      >
        {config?.onlySMS ? "Start Chat" : "Send"}
      </Button>
    );
};

export default Submit;