import React from "react";
import styled from "styled-components";
import { getBorderColor } from "../../contexts/theme";
import { useFormSettings } from "../../contexts/settings";


const SpinnerComponent = styled.div<{settings?: any}>`

    display: inline-block;
    width: 80px;
    height: 80px;

    &:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: ${(props) => `6px solid ${getBorderColor({borderColor: props.settings?.borderColor})}`};
        border-color: ${(props) => `${getBorderColor({borderColor: props.settings?.borderColor})} transparent ${getBorderColor({borderColor: props.settings?.borderColor})} transparent`};
        animation: rotate 1.2s linear infinite;
    }

    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }
`

const CentralisedContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default function Spinner() {
  const {
    inputSettings
  } = useFormSettings();

    return (
        <CentralisedContainer>
            <SpinnerComponent settings={inputSettings} />
        </CentralisedContainer>
    )
}