import React, { forwardRef, ForwardRefRenderFunction, LegacyRef } from "react";
import { FieldError } from "react-hook-form";
import styled from "styled-components";
import { ContactFormInputConfigProps } from "../../contexts/config";
import { getBorderColor } from "../../contexts/theme";

export type InputProps = {
  label: string;
  id: string;
  name: string;
  as?: "text" | "textarea";
  placeholder?: string;
  error?: FieldError;
  settings?: ContactFormInputConfigProps;
};

export const Control = styled.div<{compact?: boolean}>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: ${(props: any) => props.theme.margin.y.m};
  margin-bottom: ${(props: any) => props.compact ? 0 : props.theme.margin.y.m};
  margin-left: ${(props: any) => props.theme.margin.x.xs};
  margin-right: ${(props: any) => props.theme.margin.x.xs};
`;

export const Label = styled.label`
  padding-left: ${(props: any) => props.theme.padding.x.l};
  padding-right: ${(props: any) => props.theme.padding.x.l};
  margin-bottom: ${(props: any) => props.theme.margin.y.s};
`;

const TextArea = styled.textarea.attrs((props: {ref: any, settings: any}) => props)`
  border-radius: ${(props: any) => props.theme.border.radius.mild};
  padding-left: ${(props: any) => props.theme.padding.x.l};
  padding-right: ${(props: any) => props.theme.padding.x.l};
  border: 1px solid ${(props: any) => getBorderColor({borderColor: props.settings?.borderColor})};

  &:focus,
  &:active,
  &:hover {
    border-color: ${(props: any) => getBorderColor({borderColor: props.settings?.borderColor, darker: true})};
    box-shadow: ${(props: any) => getBorderColor({borderColor: props.settings?.borderColor, darker: true})}; 0px 0px 0px 1px;
    outline: none;
  }

  height: ${(props: any) => props.settings?.compactVersion ? '3rem' : 'unset'};
  resize: vertical;
  padding-top: ${(props: any) => props.theme.padding.y.m};
  padding-bottom: ${(props: any) => props.theme.padding.y.m};
  font-family: inherit;
  font-size: inherit;
  min-height: ${(props: any) => props.theme.element.height};

  ::placeholder {
    color: ${(props: any) => props.theme.colorsSchemes[props.settings?.placeholderColor || "gray"][400]};
  }
`;

const TextInput = styled.input<{settings: any}>`
  border-radius: ${(props: any) => props.theme.border.radius.full};
  padding-left: ${(props: any) => props.theme.padding.x.l};
  padding-right: ${(props: any) => props.theme.padding.x.l};
  height: ${(props: any) => props.theme.element.height};
  border: 1px solid ${(props: any) => getBorderColor({borderColor: props.settings?.borderColor})};

  &:focus,
  &:active,
  &:hover {
    border-color: ${(props: any) => getBorderColor({borderColor: props.settings?.borderColor, darker: true})};
    box-shadow: ${(props: any) => getBorderColor({borderColor: props.settings?.borderColor, darker: true})}; 0px 0px 0px 1px;
    outline: none;
  }

  ::placeholder {
    color: ${(props: any) => props.theme.colorsSchemes[props.settings?.placeholderColor || "gray"][400]};
  }
`;

export const InputError = styled.span`
  color: ${(props: any) => props.theme.colors.red};
  margin-left: ${(props: any) => props.theme.margin.x.m};
  margin-top: ${(props: any) => props.theme.margin.y.s};
`;

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({
  label,
  id,
  placeholder,
  as = "text",
  error = undefined,
  settings = undefined,
  ...rest
}, ref) => {
  const getPlaceholder = (
    usePlaceholders: boolean | undefined,
    placeholderText: string | undefined
  ) => usePlaceholders || typeof usePlaceholders === "undefined" ? placeholderText : "";

  return (
    <Control compact={settings?.compactVersion}>
      {settings?.usePlaceholders === false && (
        <Label htmlFor={id}>
          {label}
        </Label>
      )}
      {as === "textarea" ? (
        <TextArea
          placeholder={getPlaceholder(settings?.usePlaceholders, placeholder)}
          rows={5}
          id={id}
          settings={settings}
          {...rest}
          ref={ref as unknown as LegacyRef<HTMLTextAreaElement>}
        />
      ) : (
        <TextInput
          type="text"
          className="contact-form-input"
          placeholder={getPlaceholder(settings?.usePlaceholders, placeholder)}
          id={id}
          settings={settings}
          {...rest}
          ref={ref}
        />
      )}
      {error && <InputError>{error.message}</InputError>}
    </Control>
  );
}

export default forwardRef(Input);
