import { Country } from "react-phone-number-input";
import { ColorScheme } from "../constants";
import removeUndefinedProperties from "../utils/utils";

export type ContactFormInputConfigProps = {
  borderColor?: string;
  compactVersion?: boolean;
  usePlaceholders?: boolean;
  placeholderColor?: string;
};

export type ContactFormConfigProps = {
  merchantId: string;
  title?: string;
  subtitle?: string;
  bgColor?: string;
  textColor?: string;
  buttonColorScheme?: ColorScheme;
  noFooter?: boolean;
  compactVersion?: boolean;
  hasFileUpload?: boolean;
  allowMultipleFiles?: boolean;
  defaultCountry?: Country;
  onlySMS?: boolean;
  successTitle?: string;
  successSubtitle?: string;
} & ContactFormInputConfigProps;

export const parseContactFormConfig = (
  queryParams: URLSearchParams
): [ContactFormConfigProps | undefined, ContactFormInputConfigProps | undefined] => {
  let config: ContactFormConfigProps | undefined;
  let inputConfig: ContactFormInputConfigProps | undefined;

  const rawConfig = queryParams.get("config");
  if (rawConfig) {
    config = JSON.parse(rawConfig);
    inputConfig = {
      borderColor: config?.borderColor,
      compactVersion: config?.compactVersion,
      usePlaceholders: config?.usePlaceholders,
      placeholderColor: config?.placeholderColor,
    };

    inputConfig = removeUndefinedProperties(inputConfig);
  } else {
    const providedMerchantId = queryParams.get("merchant-id");
    if (providedMerchantId) {
      config = { merchantId: providedMerchantId };
    }
  }

  return [config, inputConfig];
};
