import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./styles/index.scss";
import ContactForm from "./components/contact-form";
import { FormSettingsContextProvider } from "./contexts/settings";
import reportWebVitals from "./reportWebVitals";
import Theme from "./contexts/theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Theme>
      <FormSettingsContextProvider>
        <BrowserRouter>
          <Routes>
              <Route path="/" element={<ContactForm />} />
              <Route path="/success" element={<ContactForm isSubmitted={true}/>} />
          </Routes>
        </BrowserRouter>
      </FormSettingsContextProvider>
    </Theme>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
