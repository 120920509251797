import React from "react";
import styled from "styled-components";
import {ContactFormConfigProps} from "../../contexts/config";

interface InputProps {
    config?: ContactFormConfigProps;
}

const PoweredBy = styled.span`
    margin-top: ${(props: any) => props.theme.margin.y.m};
    margin-bottom: ${(props: any) => props.theme.margin.y.m};
    text-align: center;
`;

export default function Footer({ config }: InputProps) {
    if (config?.noFooter) {
        return null;
    }

    return (
      <PoweredBy>
        Powered by <b>Fuzey</b>
      </PoweredBy>
    );
};