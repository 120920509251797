import React from "react";
import styled from "styled-components";
import {ContactFormConfigProps} from "../../contexts/config";

const Title = styled.h1`
    text-align: center;
    margin-top: ${props => props.theme.margin.y.m};
    margin-bottom: ${props => props.theme.margin.y.m};
`;

const SubTitle = styled.h2`
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    margin-bottom: ${props => props.theme.margin.y.m};
`;

export default function Header({
    config
}: {
    config?: ContactFormConfigProps;
}) {
    return (
        <>
            {!config?.compactVersion && config?.title && <Title>{config?.title}</Title>}
            {!config?.compactVersion && config?.subtitle && <SubTitle>{config?.subtitle}</SubTitle>}
        </>
    )
}