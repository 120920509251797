import React, {
    createContext,
    ReactNode,
    useContext,
    useState,
    useMemo
} from "react";
import { Country } from "react-phone-number-input";
import {
    ContactFormInputConfigProps,
    ContactFormConfigProps,
    parseContactFormConfig
} from "./config";
import ContactFormService from "../services/form";

type FormSettingsContextProps = {
    isLoaded?: boolean;
    errorOnLoading?: boolean
    formSettings?: ContactFormConfigProps;
    inputSettings?: ContactFormInputConfigProps;
    loadSettings: (query: URLSearchParams) => void;
}

export const FormSettingsContext = createContext({} as FormSettingsContextProps);

type FormSettingsContextProviderProps = {
    children: ReactNode
}

export const FormSettingsContextProvider = ({children}: FormSettingsContextProviderProps) => {
    const [settings, setSettings] = useState<ContactFormConfigProps | undefined>();
    const [inputSettings, setInputSettings] = useState<ContactFormInputConfigProps | undefined>();
    const [errorOnLoading, setErrorOnLoading] = useState(false);

    const getSettingsFromQuery = (queryParams: URLSearchParams) => {
        let config: ContactFormConfigProps | undefined;
        let inputConfig: ContactFormInputConfigProps | undefined;

        try {
            [config, inputConfig] = parseContactFormConfig(queryParams);
        } catch (e) {
            // misconfiguration
        }

        if (config && config.merchantId) {
            return {
                formSettings: config,
                inputSettings: inputConfig
            };
        }

        return {
            formSettings: {
                merchantId: ""
            },
            inputSettings: {}
        };
    }

    const loadSettings = async (queryParams: URLSearchParams) => {
        if (!queryParams || !queryParams.toString()) {
            setErrorOnLoading(true);
            return;
        }

        if (!settings) {
            const parsedSettings = getSettingsFromQuery(queryParams);

            if (!parsedSettings.formSettings.merchantId) {
                setErrorOnLoading(true);
                return;
            }

            const loadedSettings = await ContactFormService.getSettings(parsedSettings.formSettings.merchantId);

            if (loadedSettings || parsedSettings) {
                setSettings({
                    ...loadedSettings.formSettings,
                    ...parsedSettings.formSettings
                });
                setInputSettings({
                    ...loadedSettings.inputSettings,
                    ...parsedSettings.inputSettings,
                });
            } else {
                setErrorOnLoading(true);
            }
        }
    }

    const providerValue = useMemo(() => ({
        formSettings: settings,
        inputSettings,
        isLoaded: !!settings,
        errorOnLoading,
        loadSettings
    }), [
        settings,
        inputSettings,
        !!settings,
        errorOnLoading,
        loadSettings
    ]);

    return (
        <FormSettingsContext.Provider
            value={providerValue}
        >
            {children}
        </FormSettingsContext.Provider>
    )
}

export const useFormSettings = () => useContext(FormSettingsContext);
