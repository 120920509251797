import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import { Country } from "react-phone-number-input";
import {apiUrl, ColorScheme} from "../constants";
import { ContactFormConfigProps, ContactFormInputConfigProps } from "../contexts/config";
import removeUndefinedProperties from "../utils/utils";

const api = axios.create({
    baseURL: apiUrl
});

export enum RequestType {
    GET = "get",
    POST = "post",
    PUT = "put",
    PATCH = "patch",
    DELETE = "delete",
}

export interface ContactFormDto {
  merchant_id: string;
  name: string;
  surname: string;
  phone_number: string;
  email?: string;
  body: string;
  files?: File[];
}

export default class ContactFormService {
  public static async getCaptchaToken(
    recaptchaRef: React.MutableRefObject<ReCAPTCHA>
  ) {
    const token = await recaptchaRef?.current?.executeAsync();

    // it is necessary to reset captcha component so that it is ready for the next challenge
    // should it be necessary.
    recaptchaRef?.current?.reset();

    return token;
  }

  public static async getSettings(
    merchantId: string,
  ): Promise<{
    formSettings: ContactFormConfigProps;
    inputSettings: ContactFormInputConfigProps;
  }> {
    try {
        const { data }: {
          data: {
            bg_colour: string;
            font_colour: string;
            border_colour: string;
            title: string;
            sub_title: string;
            button_colour_scheme: ColorScheme;
            has_file_upload?: boolean;
            default_country?: Country;
          }
        } = await api[RequestType.GET](`/public/contact-form/settings/${merchantId}`);

        return {
          formSettings: removeUndefinedProperties({
            merchantId,
            title: data.title,
            subtitle: data.sub_title,
            bgColor: data.bg_colour,
            textColor: data.font_colour,
            buttonColorScheme: data.button_colour_scheme,
            hasFileUpload: data.has_file_upload || false,
            defaultCountry: data.default_country
          }),
          inputSettings: removeUndefinedProperties({
            borderColor: data.border_colour
          })
        };
    } catch (e) {
        return {
          formSettings: {
            merchantId
          },
          inputSettings: {}
        };
    }
  }

  public static async submitEnquiry(
    token: string,
    dto: ContactFormDto
  ): Promise<boolean> {
    try {
        if (dto.files) {
          const formData = new FormData();

          Object.keys(dto).forEach((key: string) => {
            if (key === "files" && dto.files) {
              Object.entries(dto.files).forEach(([_idx, file]) => {
                formData.append("files[]", file);
              });
            } else {
              formData.append(key, (dto as { [k in string]: any })[key] as string | Blob);
            }
          });

          await api[RequestType.POST](
            `/public/contact-form/messages`,
            formData,
            {
              headers: {
                captcha: token,
                "Content-Type": "multipart/form-data"
              }
            }
          );

          return true;
        }

        await api[RequestType.POST](
          `/public/contact-form/messages`,
          {
            ...dto
          },
          {
            headers: {
              captcha: token
            }
          }
        );

        return true;
    } catch (e) {
        return false;
    }
  }
}
