import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

export const getContactFormSchema = (smsOnly: boolean = false) => {
  const schema: { [key: string]: any } = {
    first_name: yup
      .string()
      .required("First Name is required.")
      .min(2, "Invalid First Name."),
    last_name: yup
      .string()
      .required("Last Name is required.")
      .min(2, "Invalid Last Name."),
    phone_number: yup
      .string()
      .required("Phone Number is required.")
      .test(
        "valid_phone_number",
        "Invalid Phone Number. Country code required (e.g +44).",
        (value) => {
          try {
            return value ? isValidPhoneNumber(value) : true;
          } catch (e) {
            return false;
          }
        }
      ),
    message: yup
      .string()
      .required("Message is required.")
      .min(5, "Invalid Message."),
    files: yup.mixed()
      .test({
        message: "We accept only PNG, JPEG, JPG and PDF files.",
        test: (files, context) => {
          if (!files) {
            return true;
          }

          return Object.entries(files).reduce((isValid: boolean, entry) => {
            const [_key, file] = entry as [string, File];

            if (!isValid) {
              return false;
            }

            const isValidFile = [
              "png", "jpeg", "jpg", "pdf"
            ].includes(file?.name.split(".").pop() || "");

            if (!isValidFile) {
              context?.createError();
            }

            return isValidFile;
          }, true);
        }
      })
      .test({
        message: `File too big, can't exceed ${MAX_FILE_SIZE}`,
        test: (files) => {
          if (!files) {
            return true;
          }

          const totalFileSize = Object.entries(files).reduce((size: number, entry) => {
            const [_key, file] = entry as [string, File];

            return size + file.size;
          }, 0);

          const isValid = totalFileSize < MAX_FILE_SIZE;

          return isValid;
        }
      })
  }

  if (!smsOnly) {
    schema.email = yup.string().email().required();
  }

  return yup.object().shape(schema);
};

export default getContactFormSchema;
