import React from "react";
import styled from "styled-components";
import { useFormSettings } from "../../contexts/settings";

const Wrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: ${(props: any) => props.theme.maxWidth};
    min-height: ${(props: any) => props.theme.minHeight};
    margin-right: auto;
    margin-left: auto;
    color: ${(props: any) => props.theme.colors.text};
`;

const Svg = styled.svg`
    margin-right: ${(props: any) => props.theme.margin.x.m};
    fill: ${(props: any) => props.theme.colorsSchemes[props.fill || "green"][500]};
`;

const Title = styled.h1`
    font-weight:  ${(props: any) => props.theme.text.bold};
    margin-top: 0;
    margin-bottom: 0;
`;

const Description = styled.p`
    margin-top: 0;
    margin-bottom: 0;
`;

// Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.
function Icon({ fill, size = 60 }: { fill: string; size?: number; }) {
    const {
        formSettings,
    } = useFormSettings();

    return (
        <Wrapper>
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                height={size}
                width={size}
            >
                <path d="M235.3 331.3C229.1 337.6 218.9 337.6 212.7 331.3L148.7 267.3C142.4 261.1 142.4 250.9 148.7 244.7C154.9 238.4 165.1 238.4 171.3 244.7L224 297.4L340.7 180.7C346.9 174.4 357.1 174.4 363.3 180.7C369.6 186.9 369.6 197.1 363.3 203.3L235.3 331.3zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z"/>
            </Svg>
            <div>
                <Title>{formSettings?.successTitle ? formSettings.successTitle : "Thank you for your enquiry."}</Title>
                <Description>{formSettings?.successSubtitle ? formSettings.successSubtitle : "A member of our team will be in touch with you as soon as possible!"}</Description>
            </div>
        </Wrapper>
    );
}

function Success() {
    return (<Icon fill="green" />);
}

export default Success;
