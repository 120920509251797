import React, { ChangeEvent, forwardRef, ForwardRefRenderFunction } from "react";
import styled from "styled-components";
import 'react-phone-number-input/style.css'
import ReactPhoneNumberInput, { Country, Props as PhoneNumberLibProps } from "react-phone-number-input";
import { InputProps, InputError, Label } from "../input";
import { getBorderColor } from "../../contexts/theme";

type PhoneInputProps = {
  defaultCountry?: Country;
  onChange?: (e: any) => void;
  setPhoneNumber?: (value: string) => void;
} & Omit<PhoneNumberLibProps<{}>, "value" | "onChange"> &
  InputProps;

const Wrapper = styled.div<{settings: any}>`
  border-radius: ${(props: any) => props.theme.border.radius.full};
  padding-top: 0.0625rem;
  padding-bottom: 0.0625rem;
  padding-left: ${(props: any) => props.theme.padding.x.l};
  padding-right: ${(props: any) => props.theme.padding.x.l};
  height: ${(props: any) => props.theme.element.height};
  border: 1px solid ${(props: any) => getBorderColor({borderColor: props.settings?.borderColor})};
  &:focus,
  &:active,
  &:hover {
    border-color: ${(props: any) => getBorderColor({borderColor: props.settings?.borderColor, darker: true})};
    box-shadow: ${(props: any) => getBorderColor({borderColor: props.settings?.borderColor, darker: true})}; 0px 0px 0px 1px;
    outline: none;
  }

  background-color: ${(props: any) => props.theme.colors.white};

  .PhoneInput {
    height: ${(props: any) => props.theme.element.height};

    &Input {
      height: ${(props: any) => props.theme.element.height};
      border: none;
      outline: none;
      background-color: ${(props: any) => props.theme.colors.transparent};

      ::placeholder {
        color: ${(props: any) => props.theme.colorsSchemes[props.settings?.placeholderColor || "gray"][400]};
      }
    }

    &CountryIcon {
      box-shadow: none;
      border-radius: ${(props: any) => props.theme.icon.borderRadius};

      &Img {
        border-radius: ${(props: any) => props.theme.icon.borderRadius};
      }
    }
  }
`

const PhoneInput: ForwardRefRenderFunction<HTMLInputElement, PhoneInputProps> = ({
  setPhoneNumber,
  onChange,
  defaultCountry,
  error,
  settings = undefined,
  ...rest
}: PhoneInputProps, ref) => {
  return (
    <>
    {settings?.usePlaceholders === false && (
      <Label htmlFor={rest.id}>
        {rest.label}
      </Label>
    )}
    <Wrapper settings={settings}> 
      <ReactPhoneNumberInput
        international={true}
        defaultCountry={defaultCountry}
        error={error}
        ref={ref as any}
        {...rest}
        onChange={(e) => {
          if (setPhoneNumber) {
            setPhoneNumber(e?.toString() || "");
          }
          // react-phone-number-input changed the default onChange event type to string
          if (onChange) {
            onChange({
              target: {
                name: rest.id,
                value: e?.toString(),
              },
            } as ChangeEvent<HTMLInputElement>);
          }
        }}
      />
    </Wrapper>
    {error && <InputError>{error?.message}</InputError>}
    </>
  );
}

export default forwardRef(PhoneInput);
